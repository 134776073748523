import React, { useEffect, useState } from "react";
import { 
  Button, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  CircularProgress,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Link
} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../utils/cartUtils";

interface Product {
  id: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
  photos: string[];
  videos: string[];
  delisted?: boolean;
  isNew?: boolean;
  isFeatured?: boolean;
}

const getProductBadge = (product: Product) => {
  if (product.quantity <= 3 && product.quantity > 0) {
    return {
      message: `Only ${product.quantity} Left`,
      style: {
        bgcolor: 'error.main',
        color: 'white'
      }
    };
  }
  
  if (product.isNew) {
    return {
      message: 'New',
      style: {
        bgcolor: 'success.main',
        color: 'white'
      }
    };
  }
  
  if (product.isFeatured) {
    return {
      message: 'Featured',
      style: {
        bgcolor: 'primary.main',
        color: 'white'
      }
    };
  }
  
  return null;
};

const ProductsPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [settings, setSettings] = useState<{
    notice: {
      enabled: boolean;
      message: string;
      link?: string;
    };
  } | null>(null);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          } as Product))
          .filter((product: Product) => !product.delisted)
          .sort((a, b) => {
            if (a.quantity <= 0 && b.quantity > 0) return 1;
            if (a.quantity > 0 && b.quantity <= 0) return -1;
            return 0;
          });
        setProducts(productsData);
      } catch (error) {
        console.error("Error loading products:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'global'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setSettings(data as any);
          if (data.notice?.enabled && data.notice?.message) {
            setNoticeOpen(true);
          }
        }
      } catch (error) {
        console.error('Error loading settings:', error);
      }
    };

    loadSettings();
  }, []);

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  const handleAddToCart = (product: Product) => {
    addToCart({
      id: product.id,
      title: product.title,
      quantity: 1,
      price: product.price,
      imageUrl: product.photos[0]
    });
  };

  const NoticeDialog = () => (
    <Dialog open={noticeOpen} onClose={() => setNoticeOpen(false)}>
      <DialogContent>
        <Typography>
          {settings?.notice.link ? (
            <>
              {settings.notice.message}{' '}
              <Link href={settings.notice.link} target="_blank" rel="noopener">
                Click here
              </Link>
            </>
          ) : (
            settings?.notice.message
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNoticeOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container sx={{ py: 4, px: { xs: 1, sm: 2, md: 3 } }}>
      {settings?.notice.enabled && <NoticeDialog />}
      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
        {products.map((product) => (
          <Grid item xs={6} md={3} key={product.id}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                position: 'relative',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                },
                opacity: product.quantity <= 0 ? 0.7 : 1
              }}
            >
              {(() => {
                const badge = getProductBadge(product);
                
                return (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      zIndex: 1,
                      py: 0.5,
                      px: 1,
                      textAlign: 'center',
                      fontSize: '0.75rem',
                      fontWeight: 'medium',
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px',
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      height: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      ...(badge ? badge.style : {
                        bgcolor: 'white'
                      })
                    }}
                  >
                    {badge?.message || ''}
                  </Box>
                );
              })()}
              <Box sx={{ 
                position: 'relative', 
                pt: '100%',
                mt: '24px'
              }}>
                <CardMedia
                  component="img"
                  image={product.photos[0]}
                  alt={product.title}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate(`/product/${product.id}`)}
                />
              </Box>
              <CardContent sx={{ p: 2 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    height: '2.5em',
                    lineHeight: '1.25em'
                  }}
                >
                  {product.title}
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 600,
                    mb: 2
                  }}
                >
                  ₹{product.price.toFixed(2)}
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<AddShoppingCartIcon />}
                  disabled={product.quantity <= 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(product);
                  }}
                  sx={{
                    bgcolor: product.quantity <= 0 ? 'grey.300' : 'white',
                    color: product.quantity <= 0 ? 'grey.500' : 'black',
                    border: '1px solid',
                    borderColor: product.quantity <= 0 ? 'grey.300' : 'black',
                    '&:hover': {
                      bgcolor: 'black',
                      color: 'white'
                    }
                  }}
                >
                  {product.quantity <= 0 ? 'Sold Out' : 'Add to Cart'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductsPage; 