import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Badge,
  Box,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Grid
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from "react-router-dom";
import { signInWithPopup, onAuthStateChanged, User, signOut } from "firebase/auth";
import { auth, googleProvider } from "./config/firebase";
import ProductsPage from "./pages/ProductsPage";
import AdminPage from "./pages/AdminPage";
import ProductPage from './pages/ProductPage';
import { getCartItemsCount, getCart } from './utils/cartUtils';
import CartPage from './pages/CartPage';
import ContactPage from './pages/ContactPage';
import PaymentPage from './pages/PaymentPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import OrderPage from './pages/OrderPage';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Analytics } from "@vercel/analytics/react"
import { getDoc, doc } from 'firebase/firestore';
import { db } from './config/firebase';
import { BatchShippingLabels } from './components/admin/BatchShippingLabels';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Fab from '@mui/material/Fab';

function AppWrapper() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cartCount, setCartCount] = useState(getCartItemsCount());
  const [user, setUser] = useState<User | null>(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const [savedDetails, setSavedDetails] = useState<any>(null);

  const [shippingDetails, setShippingDetails] = useState(() => {
    const savedDetails = localStorage.getItem('shippingDetails');
    return savedDetails ? JSON.parse(savedDetails) : {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      postalCode: ''
    };
  });

  const [isAdminUser, setIsAdminUser] = useState(false);

  useEffect(() => {
    const handleCartUpdate = () => {
      setCartCount(getCartItemsCount());
    };

    window.addEventListener('cartUpdated', handleCartUpdate);
    return () => window.removeEventListener('cartUpdated', handleCartUpdate);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const adminStatus = await isAdmin(user.uid);
        setIsAdminUser(adminStatus);
      } else {
        setIsAdminUser(false);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Load saved user details from localStorage
    const loadSavedDetails = () => {
      const shippingDetails = localStorage.getItem('shippingDetails');
      const cart = getCart();
      setSavedDetails({
        shipping: shippingDetails ? JSON.parse(shippingDetails) : null,
        cart: cart
      });
    };

    loadSavedDetails();
    window.addEventListener('cartUpdated', loadSavedDetails);
    return () => window.removeEventListener('cartUpdated', loadSavedDetails);
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      setProfileOpen(false);
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const handleSignOut = () => {
    signOut(auth);
    setProfileOpen(false);
  };

  const isAdmin = async (uid: string) => {
    try {
      const adminDoc = await getDoc(doc(db, "admins", uid));
      return adminDoc.exists();
    } catch (error) {
      console.error("Error checking admin status:", error);
      return false;
    }
  };

  const ProfileDialog = () => (
    <Dialog open={profileOpen} onClose={() => setProfileOpen(false)}>
      {user ? (
        <>
        <DialogTitle>Saved Information</DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {shippingDetails ? (
            <>
              <Typography variant="h6" gutterBottom>
                Shipping Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Name
                  </Typography>
                  <Typography variant="body1">
                    {`${shippingDetails.firstName} ${shippingDetails.lastName}`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Email
                  </Typography>
                  <Typography variant="body1">
                    {shippingDetails.email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Address
                  </Typography>
                  <Typography variant="body1">
                    {shippingDetails.address}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    City
                  </Typography>
                  <Typography variant="body1">
                    {shippingDetails.city}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    State
                  </Typography>
                  <Typography variant="body1">
                    {shippingDetails.state}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No shipping details saved yet
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setProfileOpen(false)}>Close</Button>
        {savedDetails?.shipping && (
          <Button 
            color="error" 
            onClick={handleSignOut}
            variant="contained"
          >
            Sign Out
          </Button>
        )}
      </DialogActions>
      </>
      ) : (
        <>
        <DialogTitle>Sign In</DialogTitle>
        <DialogContent>
          <Button onClick={handleGoogleSignIn} variant="contained" startIcon={<GoogleIcon />}>
            Sign in with Google
          </Button>
        </DialogContent>
        </>
      )}
    </Dialog>
  );

  return (
    <div className="App">
      <Analytics />
      {!location.pathname.includes('/batchShipping') && (
        <AppBar position="sticky" color="primary" elevation={0}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <IconButton 
              color="inherit" 
              onClick={() => navigate('/')}
              edge="start"
            >
              <img 
                src="/logo192.png" 
                alt="OZ Store Logo" 
                style={{ 
                  height: '32px',
                  width: '32px',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  padding: '4px',
                  objectFit: 'contain'
                }} 
              />
            </IconButton>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6" component="div" color="white">
                OZ Store
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1 }}>
              {isAdminUser && (
                <IconButton 
                  color="inherit"
                  onClick={() => navigate('/admin')}
                  sx={{ mr: 1 }}
                >
                  <AdminPanelSettingsIcon />
                </IconButton>
              )}
              <IconButton 
                onClick={() => setProfileOpen(true)}
                sx={{ mr: 1, color: 'white' }}
              >
                <Avatar
                  src={user?.photoURL || undefined}
                  sx={{ width: 32, height: 32 }}
                />
              </IconButton>
              <IconButton color="inherit" onClick={() => navigate('/cart')}>
                <Badge badgeContent={cartCount} color="error">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      )}

      <Box sx={{ pt: 2 }}>
        <Routes>
          <Route path="/" element={<ProductsPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/product/:productId" element={<ProductPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/payment/:orderId" element={<PaymentPage />} />
          <Route path="/order-confirmation" element={<OrderConfirmationPage />} />
          <Route path="/order/:orderId" element={<OrderPage />} />
          <Route path="/batchShipping/:batchId" element={<BatchShippingLabels />} />
        </Routes>
      </Box>

      <ProfileDialog />

      {!location.pathname.includes('/admin') && (
        <Fab
          color="success"
          sx={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            backgroundColor: 'rgba(37, 211, 102, 0.7)',
            '&:hover': {
              backgroundColor: 'rgba(18, 140, 126, 0.7)',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2px',
            width: '72px',
            height: '72px',
          }}
          onClick={() => {
            window.open('https://wa.me/918119010954', '_blank');
          }}
        >
          <WhatsAppIcon sx={{ fontSize: 48 }} />
          <Typography 
            variant="caption" 
            sx={{ 
              fontSize: '10px',
              color: 'white',
              textTransform: 'uppercase',
              letterSpacing: '0.5px'
            }}
          >
            Contact
          </Typography>
        </Fab>
      )}
    </div>
  );
}

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#00843D", // Australian green (from national colors)
        light: "#4CAF50",
        dark: "#006331"
      },
      secondary: {
        main: "#FFCD00", // Australian gold (from national colors)
        light: "#FFE066",
        dark: "#FFA000"
      },
      error: {
        main: "#FF4B4B",
        light: "#FF7373",
        dark: "#E60000"
      },
      warning: {
        main: "#FF9933", // Outback orange
        light: "#FFB366",
        dark: "#CC7A29"
      },
      info: {
        main: "#0033A0", // Deep blue (from Australian flag)
        light: "#3366CC",
        dark: "#002673"
      },
      success: {
        main: "#00843D",
        light: "#4CAF50",
        dark: "#006331"
      },
      background: {
        default: "#FFF5EA", // Soft sandy color
        paper: "#FFFFFF"
      },
      text: {
        primary: "#333333",
        secondary: "#666666"
      }
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppWrapper />
      </Router>
    </ThemeProvider>
  );
}

export default App;
