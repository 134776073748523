import React from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Box, 
  Link,
  Stack,
  Divider
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const ContactPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={0} sx={{ p: 4, border: '1px solid #e0e0e0', borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          Contact Us
        </Typography>
        
        <Divider sx={{ my: 3 }} />
        
        <Box sx={{ textAlign: 'center', my: 4 }}>
          <Typography variant="body1" paragraph>
            We'd love to hear from you! If you have any questions, concerns, or feedback, 
            please don't hesitate to reach out to us.
          </Typography>
          
          <Stack 
            direction="row" 
            spacing={1} 
            alignItems="center" 
            justifyContent="center"
            sx={{ mt: 4 }}
          >
            <EmailIcon color="primary" />
            <Link 
              href="mailto:sales@ozstore.in"
              variant="h6"
              underline="hover"
              color="primary"
            >
              sales@ozstore.in
            </Link>
          </Stack>
          
        </Box>
      </Paper>
    </Container>
  );
};

export default ContactPage; 