import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  FormControl, Select, MenuItem, Button, Box, Stack, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { Order, OrderStatus } from '../../types/admin';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface OrdersViewProps {
  orders: Order[];
  selectedOrderId: string | null;
  updatingStatus: string;
  handleStatusUpdate: (orderId: string, status: OrderStatus) => Promise<void>;
  onDeleteOrder: (orderId: string) => void;
}

const createWhatsAppLink = (phone: string, orderId: string, name: string, message: string) => {
  const formattedMessage = message
    .replace('{name}', name)
    .replace('{orderId}', orderId);
  
  return `https://wa.me/${phone}?text=${encodeURIComponent(formattedMessage)}`;
};

interface NotesDialogProps {
  open: boolean;
  onClose: () => void;
  orderId: string;
  initialNotes?: string;
  onSave: (notes: string) => Promise<void>;
}

const NotesDialog: React.FC<NotesDialogProps> = ({
  open,
  onClose,
  orderId,
  initialNotes = '',
  onSave,
}) => {
  const [notes, setNotes] = useState(initialNotes);
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    try {
      await onSave(notes);
      onClose();
    } catch (error) {
      console.error('Error saving notes:', error);
      alert('Failed to save notes');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>Order Notes</DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        <TextField
          autoFocus
          multiline
          rows={8}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          variant="outlined"
          placeholder="Enter notes about this order..."
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 1 }}>
        <Button onClick={onClose} disabled={saving}>Cancel</Button>
        <Button 
          onClick={handleSave}
          variant="contained"
          disabled={saving}
        >
          {saving ? <CircularProgress size={24} /> : 'Save Notes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const OrdersView: React.FC<OrdersViewProps> = ({
  orders,
  selectedOrderId,
  updatingStatus,
  handleStatusUpdate,
  onDeleteOrder,
}) => {
  const navigate = useNavigate();
  const [whatsappMessage, setWhatsappMessage] = useState<string>('');
  const [notesDialogOpen, setNotesDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'global'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setWhatsappMessage(data.whatsapp?.message || '');
        }
      } catch (error) {
        console.error('Error loading settings:', error);
      }
    };

    loadSettings();
  }, []);

  const handleSaveNotes = async (notes: string) => {
    if (!selectedOrder) return;
    
    try {
      await updateDoc(doc(db, 'orders', selectedOrder.id), {
        notes: notes
      });
      selectedOrder.notes = notes;
      setSelectedOrder(null);
    } catch (error) {
      console.error('Error saving notes:', error);
      throw error;
    }
  };

  const renderNotesButton = (order: Order) => (
    <Button
      variant="outlined"
      size="small"
      onClick={() => {
        setSelectedOrder(order);
        setNotesDialogOpen(true);
      }}
      sx={{
        color: order.notes ? 'purple' : 'success.main',
        borderColor: order.notes ? 'purple' : 'success.main',
        '&:hover': {
          borderColor: order.notes ? 'purple' : 'success.main',
          backgroundColor: order.notes ? 'rgba(128, 0, 128, 0.04)' : 'rgba(0, 128, 0, 0.04)'
        }
      }}
    >
      {order.notes ? 'EDIT NOTE' : 'ADD NOTE'}
    </Button>
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: '1px' }}>Customer</TableCell>
            <TableCell sx={{ padding: '1px', width: '50px' }}>Status</TableCell>
            <TableCell sx={{ padding: '1px', width: '60px' }}>Total</TableCell>
            <TableCell sx={{ padding: '1px', width: '80px' }}>Date</TableCell>
            <TableCell sx={{ padding: '1px' }}>Actions</TableCell>
            <TableCell sx={{ padding: '16px' }}>Order ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow 
              key={order.id}
              sx={{
                bgcolor: selectedOrderId === order.id ? 'action.selected' : 'inherit',
                '& .MuiTableCell-root': {
                  padding: '6px'
                }
              }}
            >
              <TableCell sx={{ maxWidth: '80px', width: '80px' }}>
                <Stack spacing={0}>
                  <Typography 
                    variant="body2" 
                    noWrap 
                    component="span" 
                    display="block"
                    sx={{ 
                      fontSize: '0.75rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%'
                    }}
                  >
                    {order.shippingDetails.firstName}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    noWrap 
                    component="span" 
                    display="block"
                    sx={{ 
                      fontSize: '0.75rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%'
                    }}
                  >
                    {order.shippingDetails.lastName}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <Select
                      value={order.status}
                      onChange={(e) => handleStatusUpdate(order.id, e.target.value as OrderStatus)}
                      disabled={order.status === 'in cart'}
                      sx={{
                        minWidth: 50,
                        fontSize: '0.75rem',
                        '& .MuiSelect-select': {
                          padding: '2px 4px',
                        },
                        bgcolor: (() => {
                          switch (order.status) {
                            case 'in cart': return 'grey.200';
                            case 'order placed': return 'warning.light';
                            case 'order confirmed': return 'info.light';
                            case 'shipped': return 'primary.light';
                            case 'delivered': return 'success.light';
                            case 'payment error': return 'error.light';
                            case 'cod': return 'rgba(128, 0, 128, 0.4)';
                            default: return 'grey.100';
                          }
                        })(),
                      }}
                    >
                      <MenuItem value="in cart" sx={{ fontSize: '0.75rem' }}>In Cart</MenuItem>
                      <MenuItem value="order placed" sx={{ fontSize: '0.75rem' }}>Order Placed</MenuItem>
                      <MenuItem value="order confirmed" sx={{ fontSize: '0.75rem' }}>Order Confirmed</MenuItem>
                      <MenuItem value="shipped" sx={{ fontSize: '0.75rem' }}>Shipped</MenuItem>
                      <MenuItem value="delivered" sx={{ fontSize: '0.75rem' }}>Delivered</MenuItem>
                      <MenuItem value="payment error" sx={{ fontSize: '0.75rem' }}>Payment Error</MenuItem>
                      <MenuItem value="cod" sx={{ fontSize: '0.75rem' }}>Cash on Delivery</MenuItem>
                    </Select>
                  </FormControl>
                  {updatingStatus === order.id && <CircularProgress size={20} />}
                </Box>
              </TableCell>
              <TableCell sx={{ maxWidth: '80px', width: '80px' }}>₹{order.total.toFixed(2)}</TableCell>
              <TableCell>
                <Stack>
                  <Typography variant="caption" color="text.primary">
                    {new Date(order.createdAt.toDate()).toLocaleDateString()}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(order.createdAt.toDate()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => navigate(`/order/${order.id}`)}
                    sx={{ fontSize: '0.75rem' }}
                  >
                    View Details
                  </Button>
                  {renderNotesButton(order)}
                  {order.status === 'in cart' && (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      onClick={() => window.open(createWhatsAppLink(
                        order.shippingDetails.phone,
                        order.id,
                        order.shippingDetails.firstName,
                        whatsappMessage
                      ), '_blank')}
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Send Reminder
                    </Button>
                  )}
                  {(order.status === 'payment error' || order.status === 'in cart') && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => onDeleteOrder(order.id)}
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Delete
                    </Button>
                  )}
                </Stack>
              </TableCell>
              <TableCell>{order.id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {notesDialogOpen && selectedOrder && (
        <NotesDialog
          open={notesDialogOpen}
          onClose={() => {
            setNotesDialogOpen(false);
            setSelectedOrder(null);
          }}
          orderId={selectedOrder.id}
          initialNotes={selectedOrder.notes}
          onSave={handleSaveNotes}
        />
      )}
    </TableContainer>
  );
}; 