import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Chip,
  Button,
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Order } from '../../types/admin';

interface CustomerData {
  name: string;
  phone: string;
  orderCount: number;
  totalSpent: number;
}

interface CustomersViewProps {
  orders: Order[];
}

export const CustomersView: React.FC<CustomersViewProps> = ({ orders }) => {
  const createWhatsAppLink = (phone: string) => {
    const message = "Hi! we are now on Instagram! Please support us and stay up to date by following us: https://www.instagram.com/ozstore.in/";
    return `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
  };

  const customers = React.useMemo(() => {
    const customerMap = new Map<string, CustomerData>();

    orders.forEach(order => {
      const phone = order.shippingDetails.phone;
      const existingCustomer = customerMap.get(phone);

      if (existingCustomer) {
        if (['order confirmed', 'delivered'].includes(order.status)) {
          existingCustomer.orderCount++;
          existingCustomer.totalSpent += order.total;
        }
      } else {
        const isCompletedOrder = ['order confirmed', 'delivered'].includes(order.status);
        
        customerMap.set(phone, {
          name: `${order.shippingDetails.firstName} ${order.shippingDetails.lastName}`,
          phone,
          orderCount: isCompletedOrder ? 1 : 0,
          totalSpent: isCompletedOrder ? order.total : 0,
        });
      }
    });

    return Array.from(customerMap.values())
      .sort((a, b) => b.totalSpent - a.totalSpent);
  }, [orders]);

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell align="center">Completed Orders</TableCell>
            <TableCell align="right">Total Spent</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => (
            <TableRow key={customer.phone}>
              <TableCell>{customer.name}</TableCell>
              <TableCell>{customer.phone}</TableCell>
              <TableCell align="center">
                {customer.orderCount > 0 ? (
                  <Chip 
                    label={customer.orderCount}
                    color="success"
                    size="small"
                  />
                ) : '0'}
              </TableCell>
              <TableCell align="right">
                ₹{customer.totalSpent.toFixed(2)}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<WhatsAppIcon />}
                  onClick={() => window.open(createWhatsAppLink(customer.phone), '_blank')}
                  sx={{
                    color: 'success.main',
                    borderColor: 'success.main',
                    '&:hover': {
                      borderColor: 'success.dark',
                      backgroundColor: 'success.light',
                    }
                  }}
                >
                  Send WhatsApp
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {customers.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Typography variant="body2" color="text.secondary">
                  No customer data available
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};