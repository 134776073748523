import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Divider,
  Stack,
  CircularProgress,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface OrderItem {
  productId: string;
  quantity: number;
  price: number;
  title: string;
  itemTotal: number;
}

interface ShippingDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
}

interface Order {
  items: OrderItem[];
  shippingDetails: ShippingDetails;
  subtotal: number;
  shipping: number;
  total: number;
  status: string;
  createdAt: any;
  receiptUrl: string;
}

interface Settings {
  delivery: {
    message: string;
  };
}

const OrderPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [settings, setSettings] = useState<Settings | null>(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        if (!orderId) throw new Error('No order ID provided');
        const orderDoc = await getDoc(doc(db, 'orders', orderId));
        
        if (!orderDoc.exists()) {
          throw new Error('Order not found');
        }

        setOrder(orderDoc.data() as Order);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Error fetching order');
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'global'));
        if (settingsDoc.exists()) {
          setSettings(settingsDoc.data() as Settings);
        }
      } catch (err) {
        console.error('Error fetching settings:', err);
      }
    };

    fetchSettings();
  }, []);

  const handleCopyOrderId = async () => {
    if (orderId) {
      await navigator.clipboard.writeText(orderId);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    }
  };

  const getDeliveryMessage = () => {
    if (!settings?.delivery?.message) return null;
    if (!order?.status || !['order placed', 'order confirmed'].includes(order.status)) return null;

    const deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + 5);
    const formattedDate = deliveryDate.toLocaleDateString('en-IN', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return settings.delivery.message.replace('{date}', formattedDate);
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error || !order) {
    return (
      <Container sx={{ py: 8 }}>
        <Typography variant="h5" color="error" textAlign="center">
          {error || 'Order not found'}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={0} sx={{ p: 4, border: '1px solid #e0e0e0', borderRadius: 2 }}>
        <Stack spacing={3}>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="h5">Order Details</Typography>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: 'white',
                  px: 2,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: (() => {
                    switch (order.status) {
                      case 'in cart':
                        return 'grey.500';
                      case 'order placed':
                        return 'warning.main';
                      case 'order confirmed':
                        return 'info.main';
                      case 'shipped':
                        return 'primary.main';
                      case 'delivered':
                        return 'success.main';
                      case 'payment error':
                        return 'error.main';
                      case 'cod':
                        return 'purple';
                      default:
                        return 'grey.500';
                    }
                  })(),
                }}
              >
                {order.status.toUpperCase()}
              </Typography>
            </Box>
            {getDeliveryMessage() && (
              <Typography 
                variant="body1" 
                sx={{ 
                  mt: 1,
                  color: 'text.secondary',
                  textAlign: 'right'
                }}
              >
                Expected Delivery: {getDeliveryMessage()}
              </Typography>
            )}
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1, 
            bgcolor: '#f5f5f5', 
            p: 2, 
            borderRadius: 1 
          }}>
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              Order ID: {orderId}
            </Typography>
            <Tooltip title={copySuccess ? "Copied!" : "Copy Order ID"}>
              <IconButton onClick={handleCopyOrderId} size="small">
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom>
              Shipping Details
            </Typography>
            <Typography variant="body1">
              {order.shippingDetails.firstName} {order.shippingDetails.lastName}<br />
              {order.shippingDetails.address}<br />
              {order.shippingDetails.city}, {order.shippingDetails.postalCode}<br />
              Phone: {order.shippingDetails.phone}<br />
              Email: {order.shippingDetails.email}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom>
              Order Summary
            </Typography>
            {order.items.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography>
                  {item.title} × {item.quantity}
                </Typography>
                <Typography>
                  ₹{(item.price * item.quantity).toFixed(2)}
                </Typography>
              </Box>
            ))}
            <Divider sx={{ my: 2 }} />
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Subtotal</Typography>
                <Typography>₹{order.subtotal.toFixed(2)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Shipping</Typography>
                <Typography>₹{order.shipping.toFixed(2)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">₹{order.total.toFixed(2)}</Typography>
              </Box>
            </Stack>
          </Box>

          {order.receiptUrl ? (
            <Box>
              <Typography variant="h6" gutterBottom>
                Payment Receipt
              </Typography>
              <Box 
                component="img" 
                src={order.receiptUrl} 
                alt="Payment Receipt"
                sx={{ 
                  maxWidth: '100%', 
                  height: 'auto',
                  borderRadius: 1
                }}
              />
            </Box>
          ) : order.status === 'cod' ? (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ color: 'purple' }}>
                Cash on Delivery
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: 'rgba(128, 0, 128, 0.8)',
                  bgcolor: 'rgba(128, 0, 128, 0.05)',
                  p: 2,
                  borderRadius: 1,
                  border: '1px solid rgba(128, 0, 128, 0.2)'
                }}
              >
                Please keep ₹{order.total.toFixed(2)} ready at the time of delivery.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="h6" gutterBottom>
                Payment Pending
              </Typography>
              <Button
                variant="contained"
                fullWidth
                onClick={() => window.location.href = `/payment/${orderId}`}
                sx={{ 
                  py: 2,
                  bgcolor: '#ffd814',
                  color: 'black',
                  '&:hover': {
                    bgcolor: '#f7ca00'
                  }
                }}
              >
                Complete Payment
              </Button>
            </Box>
          )}
        </Stack>
      </Paper>
    </Container>
  );
};

export default OrderPage; 