import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Add as AddIcon, Save as SaveIcon, Remove as RemoveIcon, Edit as EditIcon, Receipt as ReceiptIcon } from '@mui/icons-material';
import { Product, Order, OrderBatch } from '../../types/admin';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { InvoiceTemplate } from './InvoiceTemplate';

interface InventoryViewProps {
  products: Product[];
  orders: Order[];
  selectedBatch: OrderBatch | null;
  onProductUpdate: () => Promise<void>;
}

export interface ProductInventory extends Product {
  batchQuantity: number;
  currentStock: number;
  availableStock: number;
  requiredStock: number;
  newStock: number;
  isEditing?: boolean;
}

export const InventoryView: React.FC<InventoryViewProps> = ({
  products,
  orders,
  selectedBatch,
  onProductUpdate
}) => {
  const [inventory, setInventory] = useState<ProductInventory[]>([]);
  const [saving, setSaving] = useState<string[]>([]);
  const [editingProduct, setEditingProduct] = useState<ProductInventory | null>(null);
  const [newStockValue, setNewStockValue] = useState<number>(0);

  useEffect(() => {
    const calculateInventory = () => {
      const batchOrders = orders.filter(order => 
        selectedBatch?.orders.includes(order.id) && 
        order.status === 'order confirmed'
      );

      const productQuantities = products.map(product => {
        const batchQuantity = batchOrders.reduce((total, order) => {
          const orderItem = order.items.find(item => item.productId === product.id);
          return total + (orderItem?.quantity || 0);
        }, 0);

        const currentStock = product.currentStock ?? 0;
        const requiredStock = Math.max(0, batchQuantity - currentStock);

        return {
          ...product,
          batchQuantity,
          currentStock,
          availableStock: product.quantity,
          requiredStock,
          newStock: 0
        };
      });

      setInventory(productQuantities);
    };

    calculateInventory();
  }, [products, orders, selectedBatch]);

  const handleOpenDialog = (product: ProductInventory) => {
    setEditingProduct(product);
    setNewStockValue(product.currentStock);
  };

  const handleCloseDialog = () => {
    setEditingProduct(null);
  };

  const handleUpdateStock = async () => {
    if (!editingProduct) return;
    
    try {
      setSaving([...saving, editingProduct.id]);
      
      if (newStockValue < 0) {
        alert('Cannot set stock below 0');
        return;
      }

      await updateDoc(doc(db, "products", editingProduct.id), {
        currentStock: newStockValue
      });

      await onProductUpdate();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating stock:', error);
      alert('Failed to update stock');
    } finally {
      setSaving(prev => prev.filter(id => id !== editingProduct.id));
    }
  };

  const handleGenerateInvoice = () => {
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(`
        <html>
          <head>
            <title>Invoice - K.R CROSSING & LALNUNMAWII</title>
            <style>
              @media print {
                body { margin: 0; padding: 0; }
              }
            </style>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);
      
      // Render the invoice template
      const root = newWindow.document.getElementById('root');
      if (root) {
        const ReactDOM = require('react-dom');
        ReactDOM.render(<InvoiceTemplate inventory={inventory} />, root);
      }
    }
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Stock Management</Typography>
        <Button
          variant="contained"
          startIcon={<ReceiptIcon />}
          onClick={handleGenerateInvoice}
        >
          Generate Invoice
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ '& td, & th': { borderRight: '1px solid rgba(224, 224, 224, 1)' } }}>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell align="right">Stock</TableCell>
              <TableCell align="right">Batch</TableCell>
              <TableCell align="right">Surplus</TableCell>
              <TableCell align="right">Need</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory.map((product) => (
              <TableRow 
                key={product.id}
                sx={{ 
                  backgroundColor: product.requiredStock > 0 ? 'error.main' : 'inherit',
                  '&:hover': { backgroundColor: product.requiredStock > 0 ? 'error.dark' : 'action.hover' },
                  '& td:last-child': { borderRight: 'none' }
                }}
              >
                <TableCell 
                  sx={{ 
                    minWidth: '200px',
                    '&:hover': {
                      whiteSpace: 'normal',
                      maxWidth: '400px',
                      position: 'relative',
                      zIndex: 1,
                      backgroundColor: 'background.paper',
                      boxShadow: 1
                    }
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box
                      component="img"
                      src={product.photos[0]}
                      alt={product.title}
                      sx={{
                        width: 40,
                        height: 40,
                        objectFit: 'cover',
                        borderRadius: 1
                      }}
                    />
                    {product.title}
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="center">
                    {product.currentStock}
                    <IconButton 
                      size="small"
                      onClick={() => handleOpenDialog(product)}
                      disabled={saving.includes(product.id)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </TableCell>
                <TableCell align="right">{product.batchQuantity}</TableCell>
                <TableCell align="right">{product.currentStock - product.batchQuantity}</TableCell>
                <TableCell align="right">{product.requiredStock > 0 ? product.requiredStock : '-'}</TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ 
              borderTop: '2px solid rgba(224, 224, 224, 1)',
              fontWeight: 'bold',
              backgroundColor: 'action.hover'
            }}>
              <TableCell><strong>Total</strong></TableCell>
              <TableCell align="right">
                <strong>{inventory.reduce((sum, product) => sum + product.currentStock, 0)}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{inventory.reduce((sum, product) => sum + product.batchQuantity, 0)}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{inventory.reduce((sum, product) => sum + (product.currentStock - product.batchQuantity), 0)}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{inventory.reduce((sum, product) => sum + (product.requiredStock > 0 ? product.requiredStock : 0), 0)}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={!!editingProduct} onClose={handleCloseDialog}>
        <DialogTitle>Update Stock</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ pt: 1 }}>
            <Typography variant="body1">
              {editingProduct?.title}
            </Typography>
            <TextField
              autoFocus
              label="Stock Quantity"
              type="number"
              fullWidth
              value={newStockValue}
              onChange={(e) => setNewStockValue(Number(e.target.value))}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateStock();
                }
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            onClick={handleUpdateStock}
            disabled={saving.includes(editingProduct?.id ?? '')}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};