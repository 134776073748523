import React from 'react';
import { ProductInventory } from './InventoryView';

interface InvoiceTemplateProps {
  inventory: ProductInventory[];
}

export const InvoiceTemplate: React.FC<InvoiceTemplateProps> = ({ inventory }) => {
  const calculatePrice = (title: string) => title.toLowerCase().includes('small') ? 8 : 12;
  const itemsWithStock = inventory.filter(item => item.currentStock > 0);
  const subtotal = itemsWithStock.reduce((sum, item) => sum + (calculatePrice(item.title) * item.currentStock), 0);
  const date = new Date().toLocaleDateString();

  return (
    <div style={{ padding: '40px', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h3 style={{ margin: '0 0 10px 0' }}>To:</h3>
          <div style={{ fontSize: '14px' }}>
            <p style={{ margin: '3px 0' }}><strong>OzStore.in</strong></p>
            <p style={{ margin: '3px 0' }}>Noel Ramfangzauvi</p>
            <p style={{ margin: '3px 0' }}>H-5 Nursery Veng, A-SECTION,</p>
            <p style={{ margin: '3px 0' }}>Near Basketball Court</p>
            <p style={{ margin: '3px 0' }}>Aizawl, Mizoram, India (796005)</p>
            <p style={{ margin: '3px 0' }}>Email: sales@ozstore.in</p>
            <p style={{ margin: '3px 0' }}>Tel: +91 92337 32289</p>
          </div>
        </div>
        <div style={{ textAlign: 'right' }}>
          <h3 style={{ margin: '0 0 10px 0' }}>From:</h3>
          <div style={{ fontSize: '14px' }}>
            <p style={{ margin: '3px 0' }}><strong>K.R CROSSING & LALNUNMAWII</strong></p>
            <p style={{ margin: '3px 0' }}>ABN: 70 239 188 506</p>
            <p style={{ margin: '3px 0' }}>Address: 17 Goondi Close, Horsfield Bay, NSW, Australia 2256</p>
            <p style={{ margin: '3px 0' }}>Email: moii.crossing@gmail.com</p>
            <p style={{ margin: '3px 0' }}>Tel: +61 484 521 010</p>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h2 style={{ borderBottom: '2px solid #000', paddingBottom: '3px', marginBottom: '8px' }}>TAX INVOICE</h2>
        <div style={{ display: 'flex', gap: '20px', fontSize: '14px' }}>
          <p style={{ margin: 0, border: '1px solid #000', padding: '2px 6px' }}><strong>HS Tariff No:</strong> 210690</p>
          <p style={{ margin: 0 }}><strong>Date:</strong> {date}</p>
          <p style={{ margin: 0 }}><strong>Invoice Status:</strong> PAID</p>
          <p style={{ margin: 0 }}><strong>Currency:</strong> AUD</p>
        </div>
      </div>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px' }}>
        <thead>
          <tr style={{ backgroundColor: '#f0f0f0' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Quantity</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Price (AUD)</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Total (AUD)</th>
          </tr>
        </thead>
        <tbody>
          {itemsWithStock.map((item) => (
            <tr key={item.id}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img 
                    src={item.photos[0]} 
                    alt={item.title}
                    style={{
                      width: '40px',
                      height: '40px',
                      objectFit: 'cover',
                      borderRadius: '4px'
                    }} 
                  />
                  {item.title}
                </div>
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{item.currentStock}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>AUD ${calculatePrice(item.title).toFixed(2)}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>AUD ${(calculatePrice(item.title) * item.currentStock).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr style={{ fontWeight: 'bold' }}>
            <td colSpan={3} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Total:</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>AUD ${subtotal.toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>

    </div>
  );
}; 