import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDoc, doc, DocumentReference } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { Print as PrintIcon } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Label = styled('div')`
  border: 2px solid #000;
  padding: 5mm;
  display: flex;
  flex-direction: column;
  gap: 2mm;
  position: relative;
  height: 148.5mm;
  width: 105mm;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;

  @media print {
    border: 2px solid #000;
    height: 140mm;
    width: 105mm;
  }
`;

const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 2mm;
  
  h3 {
    margin: 0;
    font-size: 14px;
  }
  
  img {
    width: 20px;
    height: auto;
  }
`;

const ShippingInfo = styled('div')`
  border: 2px solid #000;
  padding: 2mm;
  margin: 2mm 0;
  background: #f8f8f8;

  h3 {
    margin: 0 0 1mm;
    font-size: 16px;
    text-transform: uppercase;
    color: #666;
  }

  .details {
    font-size: 16px;
    line-height: 1.3;
    margin: 0;
  }
`;

const ItemsTable = styled(Table)`
  th {
    font-weight: bold;
    background: #f0f0f0;
  }
  
  td, th {
    padding: 1mm;
    font-size: 12px;
    border: 1px solid #ccc;
  }
`;

const OrderNumber = styled('div')`
  position: absolute;
  top: 15mm;
  right: 15mm;
  padding: 2mm 4mm;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 14px;
  background: #fff;
`;

const PrintContainer = styled('div')`
  @media print {
    @page {
      size: A4;
      margin: 0;
    }
  }
  width: 210mm;
  margin: 0 auto;
  border: 1px solid #000;
`;

const LabelsGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0;
  height: 297mm;
  page-break-after: always;
  border: 1px solid #000;

  @media print {
    height: 100%;
  }
`;



interface ShippingDetails {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  email: string;
}

interface ShippingLabel {
  orderNumber: string;
  shippingDetails: ShippingDetails;
  items: Array<{
    title: string;
    quantity: number;
    price: number;
  }>;
  paymentMethod: 'cod' | 'paid';
  total: number;
  shipping: number;
}

export const BatchShippingLabels: React.FC = () => {
  const { batchId } = useParams<{ batchId: string }>();
  const [labels, setLabels] = useState<ShippingLabel[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        if (!batchId) {
          setError('No batch ID provided');
          return;
        }

        const batchRef = doc(db, 'batches', batchId);
        const batchDoc = await getDoc(batchRef);
        
        if (!batchDoc.exists()) {
          setError(`No batch found with ID: ${batchId}`);
          return;
        }

        const batch = batchDoc.data();
        
        if (!batch.orders || batch.orders.length === 0) {
          setError('No orders found in this batch');
          return;
        }

        const orderRefs = batch.orders.map((orderId: string) => doc(db, 'orders', orderId));
        const orderDocs = await Promise.all(orderRefs.map((ref: DocumentReference) => getDoc(ref)));

        const shippingLabels = orderDocs.map(doc => {
          const order = doc.data();
          if (!order) return null;
          
          const shipping = 100; // Fixed shipping cost
          const items = order.items?.map((item: any) => ({
            title: item?.title || '',
            quantity: item?.quantity || 0,
            price: item?.price || 0,
          })) || [];

          const subtotal = items.reduce((sum: number, item: { price: number, quantity: number }) => 
            sum + (item.price * item.quantity), 0);

          return {
            orderNumber: doc.id,
            shippingDetails: {
              firstName: order.shippingDetails?.firstName || '',
              lastName: order.shippingDetails?.lastName || '',
              address: order.shippingDetails?.address || '',
              city: order.shippingDetails?.city || '',
              postalCode: order.shippingDetails?.postalCode || '',
              phone: order.shippingDetails?.phone || '',
              email: order.shippingDetails?.email || ''
            },
            items,
            paymentMethod: order.status === 'cod' ? 'cod' : 'paid',
            shipping,
            total: subtotal + shipping
          };
        }).filter(label => label !== null) as ShippingLabel[];

        if (shippingLabels.length === 0) {
          setError('No valid orders found in this batch');
          return;
        }

        setLabels(shippingLabels);
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Error fetching orders. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [batchId]);

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', '@media print': { display: 'none' } }}>
        <Typography variant="h6">
          Shipping Labels ({labels.length} orders)
        </Typography>
        <Button 
          variant="contained" 
          onClick={handlePrint}
          startIcon={<PrintIcon />}
        >
          Print Labels
        </Button>
      </Box>
      <PrintContainer>
        {Array.from({ length: Math.ceil(labels.length / 4) }).map((_, pageIndex) => (
          <LabelsGrid key={pageIndex}>
            {labels.slice(pageIndex * 4, (pageIndex + 1) * 4).map((label) => (
              <Label key={label.orderNumber}>
                <HeaderContainer>
                  <img src="/logo192.png" alt="Oz Store Logo" />
                  <h3>Oz Store Order #{label.orderNumber}</h3>
                </HeaderContainer>
                <Box sx={{ 
                  border: '1px solid #ccc',
                  p: 0.5,
                  width: 'fit-content',
                  fontSize: '0.8rem',
                  bgcolor: label.paymentMethod === 'cod' ? 'rgba(128, 0, 128, 0.4)' : '#4caf50',
                  color: label.paymentMethod === 'cod' ? '#000' : '#fff'
                }}>
                  {label.paymentMethod === 'cod' ? 'Cash on Delivery' : 'Paid'}
                </Box>
                <ShippingInfo>
                  <h3>Ship To:</h3>
                  <div className="details">
                    {label.shippingDetails.firstName} {label.shippingDetails.lastName}<br />
                    {label.shippingDetails.address}<br />
                    {label.shippingDetails.city}, {label.shippingDetails.postalCode}<br />
                    Phone: {label.shippingDetails.phone}<br />
                    Email: {label.shippingDetails.email}
                  </div>
                </ShippingInfo>

                <div>
                  <ItemsTable size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Qty</TableCell>
                        <TableCell>Item Description</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Subtotal</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {label.items.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">{item.quantity}</TableCell>
                          <TableCell>{item.title}</TableCell>
                          <TableCell align="right">₹{item.price.toFixed(2)}</TableCell>
                          <TableCell align="right">₹{(item.quantity * item.price).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={3} align="right"><strong>Subtotal:</strong></TableCell>
                        <TableCell align="right">
                          <strong>₹{(label.total - label.shipping).toFixed(2)}</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3} align="right"><strong>Shipping:</strong></TableCell>
                        <TableCell align="right"><strong>₹{label.shipping.toFixed(2)}</strong></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3} align="right"><strong>Total:</strong></TableCell>
                        <TableCell align="right"><strong>₹{label.total.toFixed(2)}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </ItemsTable>
                </div>
              </Label>
            ))}
          </LabelsGrid>
        ))}
      </PrintContainer>
    </>
  );
};