import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  ComposedChart,
  Bar
} from 'recharts';
import { Box, Typography, Stack } from '@mui/material';
import { Order } from '../../types/admin';

interface AnalyticsViewProps {
  orders: Order[];
}

interface DailyData {
  date: string;
  paid: number;
  inCart: number;
  cod: number;
}

interface MonthlyData {
  month: string;
  paid: number;
  inCart: number;
  cod: number;
}

export const AnalyticsView: React.FC<AnalyticsViewProps> = ({ orders }) => {
  const [dailyData, setDailyData] = useState<DailyData[]>([]);
  const [monthlyData, setMonthlyData] = useState<MonthlyData[]>([]);
  const [totals, setTotals] = useState({ paid: 0, inCart: 0, cod: 0 });

  useEffect(() => {
    const processOrders = () => {
      // Generate array of last 31 days
      const last31Days: { [key: string]: { paid: number; inCart: number; cod: number } } = {};
      const today = new Date();
      
      for (let i = 30; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        const dateStr = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
        last31Days[dateStr] = { paid: 0, inCart: 0, cod: 0 };
      }

      let paidTotal = 0;
      let inCartTotal = 0;
      let codTotal = 0;

      orders.forEach(order => {
        const date = new Date(order.createdAt.toDate()).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
        
        // Only process orders from the last 31 days
        if (last31Days.hasOwnProperty(date)) {
          if (order.status === 'order confirmed' || order.status === 'delivered') {
            last31Days[date].paid += order.total;
            paidTotal += order.total;
          } else if (order.status === 'in cart') {
            last31Days[date].inCart += order.total;
            inCartTotal += order.total;
          } else if (order.status === 'cod') {
            last31Days[date].cod += order.total;
            codTotal += order.total;
          }
        }
      });

      setTotals({ paid: paidTotal, inCart: inCartTotal, cod: codTotal });

      const sortedData = Object.entries(last31Days)
        .map(([date, totals]) => ({
          date,
          paid: totals.paid,
          inCart: totals.inCart,
          cod: totals.cod
        }))
        .sort((a, b) => {
          const [aDay, aMonth, aYear] = a.date.split('/').map(Number);
          const [bDay, bMonth, bYear] = b.date.split('/').map(Number);
          return new Date(aYear, aMonth - 1, aDay).getTime() - 
                 new Date(bYear, bMonth - 1, bDay).getTime();
        });

      setDailyData(sortedData);

      // Add monthly data processing
      const monthlyTotals: { [key: string]: { paid: number; inCart: number; cod: number } } = {};
      
      orders.forEach(order => {
        const date = new Date(order.createdAt.toDate());
        const monthKey = date.toLocaleDateString('en-GB', {
          month: 'short',
          year: 'numeric'
        });
        
        if (!monthlyTotals[monthKey]) {
          monthlyTotals[monthKey] = { paid: 0, inCart: 0, cod: 0 };
        }

        if (order.status === 'order confirmed' || order.status === 'delivered') {
          monthlyTotals[monthKey].paid += order.total;
        } else if (order.status === 'in cart') {
          monthlyTotals[monthKey].inCart += order.total;
        } else if (order.status === 'cod') {
          monthlyTotals[monthKey].cod += order.total;
        }
      });

      const sortedMonthlyData = Object.entries(monthlyTotals)
        .map(([month, totals]) => ({
          month,
          paid: totals.paid,
          inCart: totals.inCart,
          cod: totals.cod
        }))
        .sort((a, b) => {
          const [aMonth, aYear] = a.month.split(' ');
          const [bMonth, bYear] = b.month.split(' ');
          return new Date(`${aMonth} 1, ${aYear}`).getTime() - 
                 new Date(`${bMonth} 1, ${bYear}`).getTime();
        });

      setMonthlyData(sortedMonthlyData);
    };

    processOrders();
  }, [orders]);

  const formatYAxis = (value: number) => {
    return value >= 1000 ? `${(value / 1000).toFixed(0)}K` : value.toString();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" spacing={4} sx={{ mb: 2 }}>
        <Typography variant="h6" color="success.main">
          Total Paid: ₹{totals.paid >= 1000 
            ? `${(totals.paid / 1000).toFixed(1)}K` 
            : totals.paid.toFixed(2)}
        </Typography>
        <Typography variant="h6" color="warning.main">
          Total In Cart: ₹{totals.inCart >= 1000 
            ? `${(totals.inCart / 1000).toFixed(1)}K` 
            : totals.inCart.toFixed(2)}
        </Typography>
        <Typography variant="h6" color="purple">
          Total COD: ₹{totals.cod >= 1000 
            ? `${(totals.cod / 1000).toFixed(1)}K` 
            : totals.cod.toFixed(2)}
        </Typography>
      </Stack>
      
      <Box sx={{ width: '100%', height: 400, mb: 4 }}>
        <ResponsiveContainer>
          <ComposedChart data={dailyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              angle={-90}
              textAnchor="end"
              height={70}
              interval={0}
              tick={{ fontSize: 10 }}
            />
            <YAxis 
              label={{ 
                value: 'Total Value (₹)', 
                angle: -90, 
                position: 'insideLeft' 
              }}
              tickFormatter={formatYAxis}
            />
            <Tooltip 
              formatter={(value: number) => `₹${value.toFixed(2)}`}
            />
            <Legend />
            <Area
              type="monotone"
              dataKey="paid"
              fill="#4caf50"
              stroke="#4caf50"
              fillOpacity={0.2}
              name="Paid Orders"
              strokeWidth={2}
              dot={false}
            />
            <Area
              type="monotone"
              dataKey="inCart"
              fill="#ff9800"
              stroke="#ff9800"
              fillOpacity={0.2}
              name="In Cart"
              strokeWidth={2}
              dot={false}
            />
            <Area
              type="monotone"
              dataKey="cod"
              fill="#9c27b0"
              stroke="#9c27b0"
              fillOpacity={0.2}
              name="Cash on Delivery"
              strokeWidth={2}
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>

      <Box sx={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <ComposedChart data={monthlyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="month" 
              angle={-45}
              textAnchor="end"
              height={60}
              interval={0}
              tick={{ fontSize: 12 }}
            />
            <YAxis 
              label={{ 
                value: 'Total Value (₹)', 
                angle: -90, 
                position: 'insideLeft' 
              }}
              tickFormatter={formatYAxis}
            />
            <Tooltip 
              formatter={(value: number) => `₹${value.toFixed(2)}`}
            />
            <Legend />
            <Bar
              dataKey="paid"
              fill="#4caf50"
              name="Paid Orders"
              barSize={20}
            />
            <Bar
              dataKey="inCart"
              fill="#ff9800"
              name="In Cart"
              barSize={20}
            />
            <Bar
              dataKey="cod"
              fill="#9c27b0"
              name="Cash on Delivery"
              barSize={20}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}; 