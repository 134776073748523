import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { 
  Container, 
  Typography, 
  Box, 
  CircularProgress,
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Breadcrumbs,
  Link,
  Grid
} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import Carousel from 'react-material-ui-carousel';
import { addToCart, getCartItemQuantity } from "../utils/cartUtils";
import { Link as RouterLink } from "react-router-dom";

interface Product {
  id: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
  photos: string[];
  videos: string[];
}

const ProductPage = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    const loadProduct = async () => {
      try {
        if (!productId) return;
        const docRef = doc(db, "products", productId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setProduct({ id: docSnap.id, ...docSnap.data() } as Product);
        }
      } catch (error) {
        console.error("Error loading product:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProduct();
  }, [productId]);

  const handleAddToCart = () => {
    if (product) {
      addToCart({
        id: product.id,
        title: product.title,
        quantity: selectedQuantity,
        price: product.price,
        imageUrl: product.photos[0]
      });
    }
  };

  const handleBuyNow = () => {
    if (product) {
      addToCart({
        id: product.id,
        title: product.title,
        quantity: selectedQuantity,
        price: product.price,
        imageUrl: product.photos[0]
      });
      navigate('/cart');
    }
  };

  const cartQuantity = getCartItemQuantity(product?.id ?? '');
  const availableQuantities = product && product.quantity > 0
    ? Array.from({ length: Math.min(product.quantity - cartQuantity, 20) }, (_, i) => i + 1)
    : [];

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!product) {
    return (
      <Container>
        <Typography variant="h5">Product not found</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Box sx={{ mb: 3 }}>
        <Breadcrumbs>
          <Link 
            component={RouterLink} 
            to="/"
            sx={{ 
              color: 'text.secondary',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            Home
          </Link>
          <Typography color="text.primary">{product.title}</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            border: '1px solid #e0e0e0',
            borderRadius: 2,
            overflow: 'hidden'
          }}>
            <Carousel
              autoPlay={false}
              animation="slide"
              indicators={true}
              navButtonsAlwaysVisible={true}
              sx={{ 
                opacity: product.quantity <= 0 ? 0.7 : 1,
                '& .MuiIconButton-root': {
                  color: 'black',
                  bgcolor: 'white',
                  '&:hover': {
                    bgcolor: 'white',
                    opacity: 0.9
                  }
                }
              }}
            >
              {product.photos.map((photo, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    pt: '100%'
                  }}
                >
                  <img
                    src={photo}
                    alt={`${product.title} - View ${index + 1}`}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      backgroundColor: '#fff'
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ p: { xs: 2, md: 3 } }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 600,
                mb: 2 
              }}
            >
              {product.title}
            </Typography>
            
            <Typography 
              variant="h5" 
              sx={{ 
                color: 'primary.main',
                fontWeight: 600,
                mb: 3
              }}
            >
              ₹{product.price.toFixed(2)}
            </Typography>

            <Typography 
              variant="body1" 
              sx={{ 
                mb: 4,
                color: 'text.secondary',
                lineHeight: 1.7
              }}
            >
              {product.description}
            </Typography>

            <Stack spacing={3}>
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <FormControl sx={{ width: 120 }}>
                  <InputLabel>Quantity</InputLabel>
                  <Select
                    value={selectedQuantity}
                    label="Quantity"
                    onChange={(e) => setSelectedQuantity(Number(e.target.value))}
                  >
                    {availableQuantities.map((num) => (
                      <MenuItem key={num} value={num}>{num}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: product.quantity <= 5 ? 'error.main' : 'success.main'
                  }}
                >
                  {product.quantity} units available
                  {product.quantity <= 3 && product.quantity > 0 && (
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        ml: 1,
                        px: 1,
                        py: 0.5,
                        bgcolor: 'error.main',
                        color: 'white',
                        borderRadius: 1,
                        fontSize: '0.75rem',
                        fontWeight: 'medium',
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px'
                      }}
                    >
                      Low Stock
                    </Box>
                  )}
                </Typography>
              </Box>

              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={2}
              >
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  startIcon={<AddShoppingCartIcon />}
                  onClick={handleAddToCart}
                  disabled={product.quantity <= 0}
                  sx={{
                    borderColor: 'black',
                    color: 'black',
                    '&:hover': {
                      borderColor: 'black',
                      bgcolor: 'black',
                      color: 'white'
                    }
                  }}
                >
                  Add to Cart
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleBuyNow}
                  disabled={product.quantity <= 0}
                  sx={{
                    bgcolor: '#4CAF50',
                    '&:hover': {
                      bgcolor: '#45a049'
                    }
                  }}
                >
                  Buy Now
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductPage; 